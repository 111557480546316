import Chart from 'chart.js';
import { html, render } from 'lit-html';
import Cleave from 'cleave.js';

const defaultArgs = {
  startingCash: 750000,
  monthlyBurn: 50000,
  monthlyRevenue: 15000,
  monthlyRevenueGrowth: 0.05,
  monthlyBurnGrowth: 0.0
};

let IncrementableNumber = (
  defaultValue: string,
  param: string,
  labelText: string
) => html`
  <div class="ipcalc-growth">
    <p class="ipcalc-growth-label">${labelText}</p>
    <div class="input-group input-number-group">
      <div class="input-group-button">
        <span class="btn-decrement">&#8212;</span>
      </div>
      <input
        class="input-number growthInput"
        type="text"
        value="${defaultValue}%"
        param="${param}"
      />
      <div class="input-group-button">
        <span class="btn-increment">+</span>
      </div>
    </div>
  </div>
`;

let AmountInput = (defaultValue: string, param: string) => html`
  <span class="amount-input-wrap">
    <span class="amount-input-prefix">$</span>
    <input
      class="amount-input"
      type="text"
      value="${defaultValue}"
      param="${param}"
    />
  </span>
`;

let Calculator = () => html`
  <div class="ipcalc-panel">
    <div class="ipcalc-section">
      <p class="ipcalc-section-label">Starting Cash</p>
      <div class="ipcalc-section-content">
        ${AmountInput('750,000', 'startingCash')}
      </div>
    </div>
    <div class="ipcalc-section">
      <p class="ipcalc-section-label">Monthly Expenses</p>
      <div class="ipcalc-section-content">
        ${AmountInput('50,000', 'monthlyBurn')}
        ${IncrementableNumber('0', 'monthlyBurnGrowth', 'Growth Rate')}
      </div>
    </div>
    <div class="ipcalc-section">
      <p class="ipcalc-section-label">Monthly Revenue</p>
      <div class="ipcalc-section-content">
        ${AmountInput('15,000', 'monthlyRevenue')}
        ${IncrementableNumber('5', 'monthlyRevenueGrowth', 'Growth Rate')}
      </div>
    </div>
    <p class="ipcalc-disclaimer">
      This illustration is for informational purposes only and should not be
      construed as investment advice
    </p>
  </div>
  <div class="ipcalc-content">
    <div class="result-message-wrap"></div>
    <div class="ipcalc-chart-wrap">
      <div class="ipcalc-tooltip"></div>
      <canvas class="chart-canvas"></canvas>
    </div>
  </div>
`;

let ResultMessage = (months: number, isProfitable: boolean) => {
  const longRunway = months >= 35;
  let messageContent;
  if (isProfitable) {
    if (months < 1) {
      messageContent = 'Your business is already profitable. Nice going!';
    } else {
      messageContent = `Your business will become profitable in ${months} months`;
    }
  } else if (longRunway) {
    messageContent = `You have at least 36 months of runway`;
  } else {
    messageContent = `You will run out of money in ${months} months`;
  }
  return html`
    <div
      class="result-message ${isProfitable
        ? 'profitable'
        : longRunway
        ? 'longRunway'
        : ''}"
    >
      ${messageContent}
    </div>
  `;
};

const seriesNames = ['Revenue', 'Expenses', 'Cash'];
const formatNumber = x =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    x
  );
let Tooltip = (
  dataPoints: { yLabel: number; datasetIndex: number; xLabel: number }[]
) => html`
  <p class="ipcalc-tooltip-title">Month ${dataPoints[0].xLabel + 1}</p>
  <div class="ipcalc-tooltip-content">
    ${dataPoints.map(
      d =>
        html`
          <div class="ipcalc-tooltip-row ${seriesNames[d.datasetIndex]}">
            <span class="ipcalc-tooltip-label"
              >${seriesNames[d.datasetIndex]}</span
            >
            <span class="ipcalc-tooltip-value">${formatNumber(d.yLabel)}</span>
          </div>
        `
    )}
  </div>
`;

export default function create(parent: HTMLElement) {
  const el = document.createElement('div');
  el.className = 'ipcalc';
  render(Calculator(), el);
  parent.append(el);

  let args = Object.assign({}, defaultArgs);
  let chartData = calculateChartData(args);

  const canvas = el.getElementsByClassName(
    'chart-canvas'
  )[0] as HTMLCanvasElement;
  const chart = createChart(canvas, chartData);
  updateResultMessage(chartData);

  const panel = el.getElementsByClassName('ipcalc-panel')[0];

  let _timeout = null;
  function updateDebounced() {
    if (_timeout) {
      clearTimeout(_timeout);
      _timeout = null;
    }
    _timeout = setTimeout(() => {
      chartData = calculateChartData(args);
      updateChart(chart, chartData);
      updateResultMessage(chartData);
      _timeout = null;
    }, 500);
  }

  const amountInputs = panel.getElementsByClassName('amount-input');
  for (let i = 0; i < amountInputs.length; ++i) {
    new Cleave(amountInputs[i], {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      noImmediatePrefix: true
    });
    amountInputs[i].addEventListener('input', event => {
      const el = event.target as HTMLInputElement;
      const rawVal = el.value;
      const val = parseInt(
        rawVal
          .replace(/\s/g, '')
          .replace(/\$/g, '')
          .replace(/,/g, '')
      );
      const param = el.getAttribute('param');
      args[param] = val;
      updateDebounced();
    });
  }

  const growthInputs = panel.getElementsByClassName('growthInput');
  for (let i = 0; i < growthInputs.length; ++i) {
    growthInputs[i].addEventListener('input', event => {
      const el = event.target as HTMLInputElement;
      const rawVal = el.value;
      const val =
        parseFloat(
          rawVal
            .replace(/\s/g, '')
            .replace(/\$/g, '')
            .replace(/,/g, '')
        ) / 100;
      const param = el.getAttribute('param');
      args[param] = val;
      updateDebounced();
    });
  }

  const incrDecrHandler = isIncrement => e => {
    const el = e.target as HTMLElement;
    const input = el.parentElement.parentElement.getElementsByTagName(
      'input'
    )[0] as HTMLInputElement;
    const val = parseFloat(input.value) || 0;
    const newVal = val + (isIncrement ? 1.0 : -1.0);
    input.value = newVal.toString() + '%';
    args[input.getAttribute('param')] = newVal * 0.01;
    updateDebounced();
  };

  const incrementButtons = panel.getElementsByClassName('btn-increment');
  for (let i = 0; i < incrementButtons.length; ++i) {
    incrementButtons[i].addEventListener('click', incrDecrHandler(true));
  }
  const decrementButtons = panel.getElementsByClassName('btn-decrement');
  for (let i = 0; i < decrementButtons.length; ++i) {
    decrementButtons[i].addEventListener('click', incrDecrHandler(false));
  }
}

function updateChart(chart, data) {
  // update the chart
  chart.data.datasets[0].data = data.rev;
  chart.data.datasets[1].data = data.burn;
  chart.data.datasets[2].data = data.cash;
  chart.update();
}

function updateResultMessage(data) {
  // update the results message
  let isProfitable, months;
  for (let i = 0; i < data.cash.length; ++i) {
    if (!isProfitable) {
      months = i;
    }
    isProfitable = data.rev[i] > data.burn[i];
  }

  render(
    ResultMessage(months, isProfitable),
    document.getElementsByClassName('result-message-wrap')[0]
  );
}

function createChart(canvas: HTMLCanvasElement, data) {
  return new Chart(canvas, {
    type: 'line',
    responsive: true,
    maintainAspectRatio: false,
    data: {
      labels: Array(data.cash.length)
        .fill(0)
        .map((_, i) => i),
      datasets: [
        {
          data: data.rev,
          label: 'Monthly Revenue',
          borderColor: '#0FBC83',
          yAxisID: 'right'
        },
        {
          data: data.burn,
          label: 'Monthly Expenses',
          borderColor: '#EF9E5B',
          yAxisID: 'right'
        },
        {
          data: data.cash,
          label: 'Cash',
          borderColor: '#22355D',
          borderWidth: 4
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              min: 0,
              max: 36,
              tickMarkLength: 5,
              maxTicksLimit: 4,
              padding: 10,
              callback: value => (value === 0 ? 'Now' : `${value} mos`),
              maxRotation: 0,
              minRotation: 0
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            id: 'left',
            display: true,
            ticks: {
              display: false,
              beginAtZero: true,
              min: 0,
              padding: 10,
              maxTicksLimit: 10
            },
            gridLines: {
              drawOnChartArea: true,
              drawTicks: false
            }
          },
          {
            id: 'right',
            display: false,
            position: 'right',
            ticks: {
              beginAtZero: true,
              min: 0,
              padding: 10,
              maxTicksLimit: 3
            },
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            }
          }
        ]
      },
      datasets: {
        line: {
          pointRadius: 0,
          fill: false,
          borderCapStyle: 'round'
        }
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        enabled: false,
        custom: model => {
          const tooltipEl = document.getElementsByClassName(
            'ipcalc-tooltip'
          )[0] as HTMLElement;
          const canvas = document.getElementsByClassName(
            'chart-canvas'
          )[0] as HTMLCanvasElement;
          if (model.dataPoints) {
            render(Tooltip(model.dataPoints), tooltipEl);
            tooltipEl.style.opacity = '1';
            if (model.xAlign === 'right') {
              tooltipEl.style.transform = 'translateX(-100%)';
            } else {
              tooltipEl.style.transform = 'unset';
            }
            tooltipEl.style.left = model.caretX + 'px';
            tooltipEl.style.top = model.caretY + 'px';
            tooltipEl.style.padding =
              model.yPadding + 'px ' + model.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          } else {
            tooltipEl.style.opacity = '0';
          }
        }
      }
    }
  });
}

function calculateChartData(opts: {
  startingCash: number;
  monthlyBurn: number;
  monthlyRevenue: number;
  monthlyBurnGrowth?: number;
  monthlyRevenueGrowth?: number;
}) {
  const {
    startingCash,
    monthlyBurn,
    monthlyRevenue,
    monthlyBurnGrowth,
    monthlyRevenueGrowth
  } = Object.assign({ monthlyBurnGrowth: 0, monthlyRevenueGrowth: 0 }, opts);

  let ret = {
    cash: [startingCash],
    rev: [monthlyRevenue],
    burn: [monthlyBurn],
    totRev: [0],
    totBurn: [0]
  };
  for (let i = 1; i < 36; ++i) {
    const curRev = ret.rev[i - 1] * (1 + monthlyRevenueGrowth);
    const curBurn = ret.burn[i - 1] * (1 + monthlyBurnGrowth);
    const curCash = ret.cash[i - 1] - curBurn + curRev;

    ret.cash.push(curCash);
    ret.burn.push(curBurn);
    ret.totBurn.push(ret.burn[i - 1] + curBurn);
    ret.rev.push(curRev);
    ret.totRev.push(ret.rev[i - 1] + curRev);

    if (curCash <= 0) break;
  }
  return ret;
}
